import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://68259e712d2815568e6eef1ebc2ada13@o4507565983989760.ingest.us.sentry.io/4507565985497088",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});